<template>
  <div class="content" style="margin: 0;">

    <transition v-if="isModal" name="modal">
      <div class="modal-mask"  style="overflow-y: scroll; padding-top: 100px; ">
        <div class="modal-container" style="width: 660px !important;overflow: scroll;max-height: 100%">
          <div style="float: right; padding-right: 10px;">
            <img @click="isModal = false" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>

          <div style="text-align: center; width: 550px; margin: 0 auto 60px;">
            <p class="content-header-text">
             Listeyi <span style="font-weight: 600">Güncelle</span>
            </p>

            <div style="margin-top:50px; display: flex; flex-direction: column; align-items: center; justify-content: center">
              <custom-input label="Liste Adı"
                            place-holder="Örn: SEO Uzmanları"
                            inputType="text"
                            v-model="title"
                            maxlength="30"
                            style="width: 366px;"/>


              <div style="display: flex; align-items: center; justify-self: flex-start; margin-top: 30px; width: 366px;">
                <custom-switch v-model="isPublicModal" />
                <p data-balloon="Bu seçeneği seçersen listeni diğer kullanıcılara paylaşabilirsin."
                   data-balloon-pos="up"
                   style="cursor:help;margin-left: 15px; color:#8b95a1; font-size: 18px; letter-spacing: -0.5px">Listeye herkes ulaşabilsin</p>
              </div>


              <custom-button @click="updateList" :disabled="buttonLoading" :button-type="'green'" style="margin-top: 50px; width: 164px;">Kaydet</custom-button>
            </div>
          </div>
        </div>
      </div>
    </transition>


    <div style="padding-bottom: 50px;">
     <div style="padding-left: 40px;">
       <div class="bread">
         <p @click="$router.push('/panel/listelerim')" class="bread-item">Listelerim</p>
         <div class="bread-icon-container">
           <img class="bread-icon" src="https://gcdn.bionluk.com/site/cicons/ic-arrows-right.svg" onload="SVGInject(this)"/>
         </div>
         <p class="bread-item-current">{{title}}</p>

       </div>

       <div class="list-row-container" style="padding-bottom: 20px">
         <div style="display: flex; justify-content: space-between; align-items: flex-end">
           <p class="list-title">{{ title }}</p>
         </div>
       </div>

     </div>


      <div v-if="pageLoaded" class="top-menu">
        <div class="menu-option-wrapper">
          <div style="display: flex; align-items: center">
            <div class="menu-option-item" :class="activeState === 'gig' ? 'active' : null" @click="activeState = 'gig'" style="margin-left: 15px" :style="activeState === 'open' ? 'border: none; width: 140px' : null">
              Servisler ({{ counts.gig }})
            </div>
            <div class="menu-option-item" :class="activeState === 'freelancer' ? 'active' : null" @click="activeState = 'freelancer'" :style="activeState === 4 ? 'border: none; width: 140px' : null">
              <span>Freelancerlar ({{counts.freelancer}})</span>
            </div>
          </div>
          <div class="icons" style="position: relative">

            <div
              data-balloon="Gizli liste(sadece ben görebilirim)"
              data-balloon-pos="up"
              v-if="!isPublic" style=" margin-top: 3px; cursor: default">
              <img class="kilit-icon" src="https://gcdn.bionluk.com/uploads/message/33921cc2-7554-4ca0-9b2e-423ec280b195.svg" onload="SVGInject(this)"/>
            </div>

            <div @click="showMenu = !showMenu" class="trash-icon-container" style="margin-right: 30px">
              <img class="dot-icon" src="https://gcdn.bionluk.com/uploads/message/20b539bb-6567-4e95-b086-88b47298ff95.svg" onload="SVGInject(this)"/>
            </div>
            <div class="setting-box"
                 v-if="showMenu"
                 v-click-away="() => showMenu = false"
            >

              <p v-if="isPublic" class="setting-box-options setting-box-options-for-click"
                 @click="getLink"
                 style="display: flex; align-items: center; margin-top: 10px;"
              >
                <img :src="'https://gcdn.bionluk.com/site/cicons/ic-link.svg'" onload="SVGInject(this)" class="side-nav-icons-m setting-box-options-for-click">

                <span style="padding-left: 10px" class="setting-box-options-for-click">Paylaş</span>
              </p>

              <p class="setting-box-options setting-box-options-for-click"
                 @click="isModal = true"
                 style="display: flex; align-items: center; margin-top: 10px;"
              >
                <img :src="'https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg'" onload="SVGInject(this)" class="side-nav-icons-m setting-box-options-for-click">

                <span style="padding-left: 10px" class="setting-box-options-for-click">Düzenle</span>
              </p>

              <p  @click="deleteList(id)" class="setting-box-options setting-box-options-for-click"
                  style="display: flex; align-items: center; margin-top: 10px;"
              >
                <img :src="'https://gcdn.bionluk.com/site/cicons/ic-trash.svg'" onload="SVGInject(this)" class="side-nav-icons-m setting-box-options-for-click">
                <span style="padding-left: 10px" class="setting-box-options-for-click">Sil</span>
              </p>


            </div>
          </div>
        </div>
      </div>


      <div class="gig-list-container">
        <div v-if="!pageLoaded && !itemsLoaded" style="display: flex;flex-wrap: wrap; margin-top: 1px">
          <div v-for="x in 3" class="card-container">
            <div class="card-image-container card">
            </div>
            <div class="card-detail">
              <div class="card-detail-container">
                <div class="card-detail-header-large card">

                </div>
                <div class="card-detail-header-small card">

                </div>
              </div>
              <div class="card-detail-user-container ">
                <div class="card-detail-user">
                  <div class="card-detail-user-image card"></div>
                  <div class="card-detail-user-name card"></div>
                </div>
                <div class="card-detail-currency card">
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-detail-seperator card">

            </div>
            <div class="card-like-currency">
              <div class="card-like-icon card"></div>
              <div class="card-currency-icon card"></div>
            </div>
          </div>
        </div>
        <template v-if="activeState === 'gig'">
          <single-gig :impression-u-u-i-d="impressionUUID" :item="fav"  v-for="(fav, findex) in gigs" class="gig-list-gig" :list-id="id" :key="fav.uuid"/>
          <div @click="getItems(id, activeState)" v-if="pageLoaded && itemsLoaded && gigs.length && counts.gig > gigs.length"  class="see-more-c" style="width: 200px; margin-right: auto; margin-left: auto">
            <p class="plus">+</p>
            <p class="text">Daha fazla göster</p>
          </div>
          <loader v-if="!itemsLoaded"/>

          <div v-if="pageLoaded && itemsLoaded && (!gigs || gigs.length === 0)" class="ee">
            <img style="width: 302px; margin-top: 30px;" src="https://gcdn.bionluk.com/uploads/message/b7de1367-db4c-4667-adcb-7ee6ef7487ce.png" />
            <p class="ee-title">Bu listede hiç iş ilanı yok.</p>
            <p class="ee-text">
              Listeye iş ilanı eklemek için ilgini çeken bir iş ilanı sayfasına git ve o sayfa üzerinden bu listeye o ilanı ekle.
            </p>
            <custom-button button-type="green" style="margin-top: 30px; margin-bottom: 30px" @click="$router.push('/bugun')">İlanları Keşfet</custom-button>

          </div>

        </template>
        <template v-if="activeState === 'freelancer'">
          <div class="cardBoxContainer" >
            <div class="cardBoxFlexC" v-for="(freelancer, fiindex) in freelancers" :key="freelancer.username">
              <div class="cardBox">
                <div
                  :data-balloon="freelancer.online_status ? 'Çevrimiçi' : freelancer.last_seen | lastSeen() + ' önce görüldü.'"
                  data-balloon-pos="left"
                  class="cardOnline" :style="freelancer.online_status ? ' background-color: #5beb17;': ' background-color: #bfc8d2;'"></div>
                <div class="rainDropcontainer">
                  <div class="firstLayer"></div>
                  <div class="secondLayer"></div>
                  <div class="thirdLayer"></div>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'favorites')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'favorites')"
                    :to="'/'+freelancer.username">
                    <img :src="freelancer.avatar" class="freelancerAvatar">
                  </router-link>
                </div>
                <p class="username">{{freelancer.username}}</p>
                <p class="title">{{freelancer.title}}</p>
                <div class="rating">
                  <img style="width: 14px; height: 14px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
                  <div v-if="freelancer.rating && freelancer.rating.commentCount">
                    <span class="rate">{{freelancer.rating.commentRating === 5 ? '5.0' : freelancer.rating.commentRating }}</span>
                    <span class="count"> ({{freelancer.rating.commentCount}})</span>
                  </div>
                </div>

                <div class="reText">

                  <div @click="removeFromList('freelancer', freelancer, fiindex)" class="followers-count">
                    <div class="inner-text"> <span style="font-weight: 600; color: #2d3640;">Listeden Çıkar</span></div>
                  </div>

                </div>

              </div>

            </div>
            <div @click="getItems(id, activeState)" v-if="pageLoaded && itemsLoaded && freelancers.length && counts.freelancer > freelancers.length"  class="see-more-c" style="width: 200px; margin-right: auto; margin-left: auto">
              <p class="plus">+</p>
              <p class="text">Daha fazla göster</p>
            </div>
            <loader v-if="!itemsLoaded"/>
          </div>
          <div v-if="pageLoaded && itemsLoaded && (!freelancers || freelancers.length === 0)" class="ee">
            <img style="width: 302px; margin-top: 30px;" src="https://gcdn.bionluk.com/uploads/message/b7de1367-db4c-4667-adcb-7ee6ef7487ce.png" />
            <p class="ee-title">Bu listede hiç freelancer ilanı yok.</p>
            <p class="ee-text">
              Listeye freelancer eklemek için ilgini çeken bir freelancer'ın profil sayfasına git ve o sayfadan üzerinden bu listeye o freelancer'ı ekle.
            </p>
            <custom-button button-type="green" style="margin-top: 30px; margin-bottom: 30px" @click="$router.push('/bugun')">Freelancerları Keşfet</custom-button>

          </div>
        </template>

      </div>

    </div>

  </div>

</template>

<script>
  import CustomInput from "@/pages/components/CustomInput"
  import CustomButton from "@/pages/components/CustomButton"
  import singleGig from "@/pages/components/singleGig"
  import CustomSwitch from "@/pages/components/CustomSwitch"

  export default {
    name: "src-pages-body-workstation-favorites-v2",
    components: { singleGig, CustomInput, CustomButton, CustomSwitch },
    data() {
      return {
        id: null,
        title: '',
        slug: '',
        gigs: [],
        freelancers: [],
        gigOffset: 0,
        isPublic: false,
        isPublicModal: false,
        freelancerOffset: 0,
        counts: [{ gig: 0, freelancer: 0 }],
        buttonLoading: false,
        activeState: 'gig',
        impressionUUID: '',
        pageLoaded: false,
        itemsLoaded: false,
        showMenu: false,
        isModal: false,
      }
    },
    watch: {
      activeState(newVal, oldVal) {
        if (newVal !== oldVal && this.pageLoaded) {
          if(newVal === 'freelancer' && !this.freelancers.length) {
            this.freelancerOffset = 0
            this.getItems(this.id, newVal)
          }
          if(newVal === 'gig' && !this.gigs.length) {
            this.gigOffset = 0
            this.getItems(this.id, newVal)
          }
        }
      },
      isModal(newVal){
        if (newVal) {
          this.isPublicModal = this.isPublic
        }
      }
    },
    methods: {
      getLink() {
        const el = document.createElement('textarea');
        el.value = 'https://bionluk.com/liste/'+this.slug;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.global.infoToast({description: 'Linki kopyalandı. 👍'})
        this.showMenu = false;
      },
      removeFromList(type, item, index){
        if(this.buttonLoading) return false;

        if(type === 'freelancer'){
          this.api.general.crudList('delete_item', 'freelancer', this.id, item.id, this.$Progress)
            .then(({data}) => {
              let result = data;
              if(result.success){
                this.buttonLoading = false;
                this.freelancers.splice(index, 1);
                this.counts.freelancer--;

              } else {
                this.buttonLoading = false;
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            })
        }

      },
      updateList(){
        if(this.buttonLoading) {return false}

        this.buttonLoading = true;
        this.api.general.crudList('update', this.title, this.id, null, this.$Progress, 0, '', this.isPublicModal ? 1 : -1)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.buttonLoading = false;
              this.isModal = false;

              this.isPublic = result.data.is_public === 1
              this.title = result.data.title;
              this.slug = result.data.slug;
              this.$toasted.global.infoToast({description: result.message});

            } else {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })

      },
      deleteList(id){
        if(this.buttonLoading) {return false}
        this.buttonLoading = true;
        let alert = "Listeyi silmen halinde, liste içindeki tüm kayıtlar da silinir. Onaylıyor musun?";
        if (confirm(alert) === true ) {
          this.api.general.crudList('delete', '', id, null, this.$Progress)
            .then(({data}) => {
              let result = data;
              if(result.success){
                this.$toasted.global.infoToast({description: result.message});
                this.$router.push('/panel/listelerim');
              } else {
                this.buttonLoading = false;
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            })
        } else {
          this.buttonLoading = false;
        }

      },
      getItems(id, state) {
        this.itemsLoaded = false;
        let offset = state === 'gig' ? this.gigOffset : this.freelancerOffset
        this.api.general.crudList('get', state, id, null, this.$Progress, offset)
          .then(({data}) => {
            let result = data
            if (result.success) {
              if (state === 'gig') {
                this.gigs = offset > 0 ? this.gigs.concat(result.data.gigs) : result.data.gigs
                this.gigOffset = offset + 12
              }
              if (state === 'freelancer') {
                this.freelancers = offset > 0 ? this.freelancers.concat(result.data.freelancers) : result.data.freelancers
                this.freelancerOffset = offset + 12
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push('/panel/listelerim');
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
        .finally(() => {
          this.itemsLoaded = true
          this.buttonLoading = false
        })
      },
      getList(id) {
        this.buttonLoading = true;
        this.api.general.crudList('get_light','', id, null, this.$Progress)
          .then(({data}) => {
            let result = data;
            if(result.success){
              this.pageLoaded = true;
              this.title = result.data.title;
              this.slug = result.data.slug;
              this.counts = result.data.counts;
              this.gigs = result.data.gigs;
              this.freelancers = result.data.freelancers;
              this.isPublic = result.data.is_public === 1;
              if(result.data.counts.gig === 0 && result.data.counts.freelancer > 0){
                this.activeState = 'freelancer';
              }
              this.getItems(id, this.activeState)

              this.$store.state.indexHTMLTitle = result.data.title + ' - Liste: ' +  id + ' - Bionluk';

              this.Helper.trackEvents.pageView({ title:result.data.title, sender_id:id});


            } else {
              this.pageLoaded = true;
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push('/panel/listelerim');
            }
          })
          .catch(err => {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      }
    },
    created() {
      this.impressionUUID = this.Helper.generateUUID();
      this.id = this.$store.state.routerParams[0];
      this.getList(this.id);

      this.EventBus.$on('removeItemFromList', payload => {
        if(payload?.id){
          let id = payload.id;
          let index = this.gigs.findIndex(l => l.id === id);
          if(index > -1){
            this.gigs.splice(index,1);
            this.counts.gig--;
          }

        }
      });

    },
    beforeDestroy() {
      this.EventBus.$off('removeItemFromList');
    }
  }
</script>

<style scoped lang="scss">

.see-more-c{
  text-align: center; width: 100%;
  display: flex;
  align-items: center;justify-content: center;
  color: #2d3640;
  cursor: pointer;
  margin-top: -15px;
  margin-bottom: 30px;



  .plus{
    font-size: 38px;
    font-weight: 300;
    padding-right: 10px;
  }
  .text{
    font-size: 18px;
    font-weight: 500;
    padding-top: 9px;
    &:hover{
      text-decoration: underline;
    }

  }
}

.ee{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ee-title{
    margin-top: 50px;
    font-size: 26px;
    font-weight: 600;
    color: #2d3640;
  }
  .ee-text{
    width: 547px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 300;

    line-height: 1.88;
    text-align: center;

    color: #5e6b79;
  }
}

.followers-count{
  padding-left: 30px;
  padding-right: 30px;
  height: 34px;
  border-radius: 2px;
  border: dashed 1px #bfc8d2;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  .inner-text {
    height: 18px;
    font-size: 14px;
    text-align: center;
    color: #5e6b79;
  }
  &:hover{
    border: solid 1px #2d3740;
  }
}

.side-nav-icons-m /deep/ {
  width: 18px!important;
  height: 18px;
  path {
    opacity: 0.8;
    fill: #8b95a1;
  }
}
.side-nav-icons-m-active /deep/ {
  width: 18px!important;
  height: 18px;
  path {
    fill: #fd4056;
  }
}

.setting-box {
  position: absolute;
  width: 140px;
  border-radius: 2px;
  background-color: #ffffff;
  padding-bottom: 10px;

  //    border: solid 1px #bfc8d2;
  right: 18px;
  top: 30px;
  z-index: 10;
  box-shadow: 0 1px 10px 0 rgba(45, 54, 64, 0.2);
  border: solid 1px rgba(94, 107, 121, 0.1);


  .setting-box-options {
    cursor: pointer;
    font-size: 14px;
    line-height: 2.29;
    color: #959aa8;
    margin-left: 19px;

    &:hover {
      color: #2d3740;
    }
  }
}

.setting-box::after {
  content: '';
  position: absolute;
  right: 15px;
  top: -7.8px;
  width: 15px;
  height: 15px;
  border-top: solid 1px rgba(94, 107, 121, 0.2);
  border-left: solid 1px rgba(94, 107, 121, 0.2);
  box-shadow: 0 1 10px -5px rgba(45, 54, 64, 0.2);
  border-bottom: 0;
  border-right: 0;
  border-radius: 20% 0 0 0 / 25%;
  transform: rotate(45deg);
  background-color: #fff;
}

.bread{
  display: flex;

  padding-top: 30px;

  .bread-item{
    font-size: 14px;
    color: #8b95a1;
    cursor: pointer;
  }

  .bread-item-current{
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
  }

  .bread-icon-container {
    margin-left: 7px;
    margin-right: 7px;
    .bread-icon /deep/{
      width: 12px;
      height: 12px;
      path {
        fill:#8b95a1
      }
    }
  }

}

.icons{

  display: flex;


  .trash-icon-container {
    cursor: pointer;
    margin-left: 25px;
    &:hover{
      opacity: 0.8;
    }
    .trash-icon /deep/{
      width: 20px;
      height: 20px;
      path {
        fill: #b2bcc8;
      }
    }

    .kilit-icon /deep/{
      width: 15px;
      height: 15px;
      path {
        fill: #bfc8d2;
      }
    }

    .dot-icon /deep/{
      width: 24px;
      height: 24px;
      path {
        fill: #5e6b79;
      }
    }
  }
}

.top-menu{
  width: 880px;
  height: 100px;
  border-radius: 10px;

  margin-left: 40px;
  margin-bottom: 30px;


  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;
  align-items: center;

  display: flex;
  justify-content: space-between;
  .menu-option-wrapper{
    width: 820px;
    margin-left: 30px;
    height: 60px;
    background: #fff;
    border-radius: 5px;
    border: solid 1px #eaedf2;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-option-item{
      width: 139px;
      height: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      font-size: 14px;
      color: #5e6b79;
      line-height: 17px;
      cursor: pointer;
      border-radius: 0.5px;

      &:nth-child(4) {
        border: none;
        width: 140px;
      }
      &.active{
        width: 140px;
        height: 38px;
        line-height: 37px;
        border-radius: 2px;
        background-color: #fd4056;
        border: none;
        color: #ffffff;
      }
    }
  }
  .menu-search-wrapper{
    height: 60px;
    width: 183px;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: rgba(244, 245, 247, 0.2);
    margin: auto 20px auto 20px;
    cursor: pointer;
    .search-icon /deep/ {
      width: 22px;
      height: 22px;
      path{
        fill: #8b95a1;
      }
    }
  }

  .menu-search-active-wrapper{
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: rgba(244, 245, 247, 0.2);
    margin: auto 20px;
    display: flex;
    align-items: center;
    .delete-icon /deep/ {
      width: 22px;
      height: 22px;
      path{
        fill: #2d3640;
      }
    }
    input{
      float: right;
      margin-right: 10px;
      width: calc(100% - 24px);
      background: rgba(244, 245, 247, 0);
      font-size: 16px;
      font-weight: 600;
      color: #2d3640;
      text-align: left;
      border: none;
      box-sizing: border-box;
    }
  }
}

.list-row-container{
  margin-top: 30px;
  width: 100%;

  .list-title{
    font-size: 40px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c4654;
  }
}


.content-header-text{
  font-size: 34px;
  font-weight: normal;
  color: #3c4654;
}
.content-header{
  margin-top: 31px;
  margin-bottom: 13px;
  width: 849px;
  align-items: center;
}


.gig-list-container {

  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 40px;


  .gig-list-gig{
    margin-bottom: 40px;
    margin-left: 24px;
    &:nth-child(3n+1) {
      margin-left: 0;
    }
  }

  &:hover {
    .swiper-button-prev, .swiper-button-next {
      visibility: visible;
    }
  }
}

  .gigContainer {
    padding-bottom: 10px;
    width: 900px;
    margin: 0 auto;
    padding-top: 20px;
  }

  .gig-main-div {
    display: inline-block;
    margin-bottom: 40px;
    margin-left: 30px;
    width: 263px;
    height: 296px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
  }

  .a3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 10px;
  }


  .swiper-button-prev {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_left.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    left: 0;
    opacity: 1;
    border-left: none;
  }

  .swiper-button-next {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_right.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    right: 0;
    opacity: 1;
    border-right: none;
  }

  .swiper-button-disabled {
    opacity: 0.7;
    pointer-events: all !important;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }


.card-container {
  width: 278px;
  height: 298px;
  background-color: #ffffff;
  border: solid 1px #dfe4ec;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;

  &:nth-child(4n+1) {
    margin-left: 0;
  }
}

.card-image-container {
  height: 148px;
  background-color: #fafbfc;
  border-bottom: solid 1px #dfe4ec;
}

.card-detail-container {
  padding: 15px;
}

.card-detail-header-large {
  width: 234px;
  height: 16px;
  background-color: #e1e6ed;
  margin-bottom: 4px;
}

.card-detail-header-small {
  width: 117px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-image {
  width: 28px;
  height: 28px;
  background-color: #dfe4ec;
  border-radius: 50%;
  margin-right: 8px;
}

.card-detail-user-name {
  width: 50px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-currency {
  width: 63px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 15px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe4ec;
}

.card-detail-user {
  display: flex;
  align-items: center;
}

.card-like-icon {
  width: 16px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-currency-icon {
  width: 42px;
  height: 20px;
  background-color: #bdc5d0;
}

.card-like-currency {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 15px;
}

.card {
  animation-name: loading;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}


.cardBoxContainer{

  transition: transform 0.5s ease 0s;
  visibility: visible;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y:hidden;
  .cardBoxFlexC{
    display: flex;
    margin-right: 24px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  .cardBox{

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 200px;
    height: 311px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    .cardOnline{
      position: absolute;
      margin-left: 170px;
      margin-top:15px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .profileButton{
      background-color: white;
      width: 140px;
      height: 34px;
      padding-bottom: 2px;
      border-radius: 2px;
      border: solid 1px rgba(45, 54, 64, 0.2);

      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
      transition: .2s;
      &:hover{
        background-color: #2d3640!important;
        color:#fff
      }

    }
    .username{
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      text-align: center;
      color: #2d3640;
    }

    .title{
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 16px;
      margin-top: 4px;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.8;
      letter-spacing: -0.5px;
      text-align: center;
      color: #8b95a1;
    }


    .rating{

      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      .rate{
        margin-left: 2px;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffbe00;
      }

      .count{
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d3640;
      }
    }


    .reText{


      text-align: center;
      margin-top: 23px;
      height: 14px;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2d3640;

      display: flex;
      span{
        font-weight: bold;
      }
      p{
        line-height: 1;
        margin-left: 5px;
      }
      .reTextIcon /deep/ {
        width: 14px;
        height: 14px;
        path {
          fill: #8b95a1;
        }
      }
    }
  }

  .pBox{
    width: 277px;
    height: 274px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    position: relative;
    display: flex;


    flex-direction: column;
    align-items: center;
    margin-left: 28px;
    margin-bottom: 30px;

    .header{

      display: flex;
      align-items: center;
      height: 67px;
      width: 100%;

    }

    .media{
      width: 277px; height: 158px;
      cursor: pointer;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .avatar{
      margin-left: 11px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
    }
    .username-p{

      font-size: 11px;
      font-weight: 300;
      color: #5e6b79;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 14px;
      max-width: 200px;

      a{
        font-size: 11px;
        font-weight: 600;
        color: #fa4a5e;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .title{
      font-weight: 600;
      color: #2d3640;
      font-size: 14px;
    }


  }

  .playIcon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -130%);
    height: 42px;
    width: 42px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    img {
      height: 42px;
      width: 42px;
    }
  }

}



.freelancerAvatar {
  width: 91px;
  height: 91px;
  border-radius: 100%;
  border: 2px solid #eaeaea;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 4;
}

.rainDropcontainer {
  position: relative;
  display: block;
  width: 132px;
  height: 132px;
  margin-top: 15px;
}


.firstLayer {
  width: 108px;
  height: 108px;
  border-radius: 100%;
  opacity: 0.1;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 3;
}

.secondLayer {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  opacity: 0.08;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
}

.thirdLayer {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  opacity: 0.05;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}

</style>
